<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Advance Account Statement</div>
    <div
      class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      "
    >
      <button
        type="button"
        @click="AccountStatementFilterModalOpen()"
        class="header-filter-btn-outer btn btn-light"
        title="Filter"
        :disabled="dissableFilterButton == true"
      >
        <span class="custom-filter-apply-alert" v-if="filterflag"></span>
        <i class="pi pi-filter custom-text-primary" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  
  <div class="custom-ultima-datatable">
    <DataTable
      :value="mainAccountStatementList"
      :scrollable="true"
      scrollHeight="flex"
      :paginator="true"
      :rows="10"
      :lazy="true"
      :rowHover="true"
      :totalRecords="totalRecords"
      @page="onPage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      :loading="loading"
    >
    <template v-if="!loading" #empty>No records found.</template>
    <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
      <Column
        field="date"
        header="Date"
        headerStyle="width: 20%"
        bodyStyle="width: 20%"
      >
        <template #body="{data}">
          <div class="label-subheading" v-if="data.transaction_type == 2">{{data.credit_date}}</div>
          <div class="label-subheading" v-if="data.transaction_type == 1">{{data.debit_date}}</div>
        </template>
      </Column>
      <Column
        field="particulars"
        header="Particulars"
        headerStyle="width: 44%"
        bodyStyle="width: 44%"
      >
        <template #body="{data}">
          <div class="label-subheading">{{data.perticulars}}</div>
        </template>
      </Column>
      <Column
        field="credit"
        header="Credit"
        headerStyle="width: 12%"
        bodyStyle="width: 12%"
        class="justify-content-end"
      >
        <template #body="{data}">
          <div class="label-subheading">
            {{ vueNumberFormat(data.credit_amount, { prefix: '₹ ', decimal: '.', thousand: ',', precision: 2, acceptNegative: true })}}
          </div>
        </template>
      </Column>
      <Column
        field="debit"
        header="Debit"
        headerStyle="width: 12%"
        bodyStyle="width: 12%"
        class="justify-content-end"
      >
        <template #body="{data}">
          <div class="label-subheading">
            {{ vueNumberFormat(data.debit_amount, { prefix: '₹ ', decimal: '.', thousand: ',', precision: 2, acceptNegative: true })}}
          </div>
        </template>
      </Column>
      <Column
        field="balance"
        header="Balance"
        headerStyle="width: 12%"
        bodyStyle="width: 12%"
        class="justify-content-end"
      >
        <template #body="{data}">
          <div class="label-subheading">
            {{ vueNumberFormat(data.balance_amount, { prefix: '₹ ', decimal: '.', thousand: ',', precision: 2, acceptNegative: true })}}
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
  <!-- filter start here -->
  <div class="modal-mask" v-if="accountstatementmodalstatus">
    <div
      class="
        modal-dialog modal-xs modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Filter By
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="AccountStatementFilterModalClose()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">From Date</label>
                <Calendar
                  v-model="fromdate"
                  :showIcon="true"
                  class="custom-ultima-calendar"
                  placeholder="DD/MM/YYYY"
                  dateFormat="dd/mm/yy"
                  :manualInput="false"
                  :monthNavigator="true"
                  :yearNavigator="true"
                  :yearRange="'2000:' + +new Date().getFullYear()"
                  appendTo="body"
                />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label class="form-label">To Date</label>
                <Calendar
                  v-model="todate"
                  :showIcon="true"
                  class="custom-ultima-calendar"
                  placeholder="DD/MM/YYYY"
                  dateFormat="dd/mm/yy"
                  :manualInput="false"
                  :monthNavigator="true"
                  :yearNavigator="true"
                  :yearRange="'2000:' + +new Date().getFullYear()"
                  appendTo="body"
                />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <div class="custom-form-group">
                <label for="accountentries" class="form-label"
                  >Show Entries</label
                >
                <div class="custom-group-radio-box-btn">
                  <input
                    type="radio"
                    class="btn-check"
                    v-model="accountentries"
                    name="accountentries"
                    id="credit"
                    autocomplete="off"
                    v-bind:value="2"
                  />
                  <label
                    class="btn custom-radio-box-btn text-capitalize"
                    for="credit"
                    >Credit</label
                  >
                  <input
                    type="radio"
                    class="btn-check"
                    v-model="accountentries"
                    name="accountentries"
                    id="debit"
                    autocomplete="off"
                    v-bind:value="1"
                  />
                  <label
                    class="btn custom-radio-box-btn text-capitalize"
                    for="debit"
                    >Debit</label
                  >
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 m-0">
            <div class="d-flex justify-content-between">
              <button
                :disabled="filterButtonShow == false"
                class="btn filter-apply-btn"
                @click="applyFilteStatement(fromdate, todate, accountentries)"
              >
                Apply Filter
              </button>
              <button
                class="btn btn-link filter-reset-btn"
                @click="resetFilterMonth"
              >
                Reset All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- filter end here -->
</template>
<script>
import ApiService from "../../../service/ApiService";
import moment from "moment";
export default {
  data() {
    return {
      mainAccountStatementList: null,
      totalRecords: 0,
      loading: false,
      accountstatementmodalstatus: false,
      fromdate: "",
      todate: "",
      accountentries: null,
      filterflag: false,
      dissableFilterButton: false,
      filterButtonShow: false,
    };
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  mounted() {
    this.loading = true;
    this.getmainoradvanceaccountstatement({type:2});
  },
  watch: {
    fromdate() {
      this.filterChanges();
    },
    todate() {
      this.filterChanges();
    },
    accountentries() {
      this.filterChanges();
    },
  },
  methods: {
    filterChanges() {
      if (
        this.fromdate != "" ||
        this.todate != "" ||
        this.accountentries != null
      ) {
        this.filterButtonShow = true;
      } else {
        this.filterButtonShow = false;
      }
    },
    applyFilteStatement(fromdate, todate, accountentries) {
      this.loading = true;
      this.filterflag = true;
      this.accountentries = accountentries;
      this.accountstatementmodalstatus = false;
      if (fromdate != "" && fromdate != null) {
        this.fromdate = moment(fromdate).format("YYYY-MM-DD");
      }
      if (todate != "" && todate != null) {
        this.todate = moment(todate).format("YYYY-MM-DD");
      }
      this.getmainoradvanceaccountstatement({
        type:2,
        page_no: this.page_no,
        fromdate: this.fromdate,
        todate: this.todate,
        accountentries_status: this.accountentries,
      });
    },
    resetFilterMonth() {
      this.fromdate = "";
      this.todate = "";
      this.accountentries = null;
      this.getmainoradvanceaccountstatement({type:2});
      this.filterflag = false;
    },
    getmainoradvanceaccountstatement(e) {
      this.loading = true;
      this.ApiService.getmainoradvanceaccountstatement(e).then((data) => {
        if (data.status == 200) {
          this.mainAccountStatementList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
          if (this.filterflag) {
            this.dissableFilterButton = false;
          }
        } else {
          this.loading = false;
          this.mainAccountStatementList = null;
          if (this.filterflag) {
            this.dissableFilterButton = false;
          } else {
            this.dissableFilterButton = true;
          }
        }
      });
    },
    onPage(event) {
      this.loading = true;
      this.page_no = event.page;
      this.getmainoradvanceaccountstatement({
        type:2,
        page_no: this.page_no,
        fromdate: this.fromdate,
        todate: this.todate,
        accountentries_status: this.accountentries,
      });
    },
    AccountStatementFilterModalOpen() {
      this.accountstatementmodalstatus = true;
    },

    AccountStatementFilterModalClose() {
      this.accountstatementmodalstatus = false;
    },
  },
};
</script>
<style scoped>
.billing-dashboard-detail-outer {
  padding: 16px;
  margin-bottom: 20px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  position: relative;
}
.service-plan-box-outer {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
  padding: 16px;
}
.service-plan-box-outer .chart-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.service-plan-box-outer .plan-header-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  user-select: none;
}
.main-account-scroll-datatable {
    height: calc(100vh - 235px);
}
</style>